@charset 'utf-8';

/* Set the size of the div element that contains the map */
#map {
  height: 500px; /* The height is 400 pixels */
  width: 100%; /* The width is the width of the web page */
}
.color-sec{
  color: $secondary-color;
}

.color-pri{
  color: $primary-color;
}

.color-ter{
  color: #CCB614;
}

.color-darkgrey{
  background: $dark-gray;
}

.bg-pri {
  background: $primary-color;
}

.no_bg, table.unstriped tbody{
  background: none!important;
  font-weigth:bold;
  color:#FFF;
  border:none!important;
  font-size:1.2rem;
}
